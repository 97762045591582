<template>
  <b-form-group
    label="Başlık(İngilizce)"
    label-for="title_en"
  >
    <b-form-input
      id="title_en"
      v-model="dataItem.title_en"
      placeholder="Başlık(İngilizce)"
    />
  </b-form-group>
</template>

<script>
import { localize } from 'vee-validate'
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  name: 'TitleEn',
  components: {
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['units/dataItem']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
